window.addEventListener('load', () => {
  Utils.init();
  listOrder();
  dropdownSelect();
  scrollHeader();
  //lectureSticky();
});

/**
 * 공통함수
 */
const Utils = {
  /**
   * Set default event
   */
  init: () => {
    Utils.setDatepicker();
    Utils.setTableCheckbox();
    //Utils.setValidation();
    Utils.setCustomDropdownSelect();
    Utils.inputClear();
    Utils.setPhoneNumber();
    Utils.setTextarea();
    Utils.setHeaderEvent();
    //Utils.searchMain();
  },
  /**
   * setPhoneNumber
   */
  setPhoneNumber() {
    $('input.phone').on('keyup', function() {
      let value = $(this).val();
      if (!value) { return ""; }
      value = value.replace(/[^0-9]/g, "");

      let result = [];
      let restNumber = "";
    
      // 0xx-yyyy-zzzz
      result.push(value.substr(0, 3));
      restNumber = value.substring(3);
  
      if (restNumber.length === 7) {
        // 7자리만 남았을 때는 xxx-yyyy
        result.push(restNumber.substring(0, 3));
        result.push(restNumber.substring(3));
      } else {
        result.push(restNumber.substring(0, 4));
        result.push(restNumber.substring(4));
      }
    
      //return result.filter((val) => val).join("-");
      $(this).val(result.filter((val) => val).join("-"));

      let saveNum = $(this).val().replace(/-/g, '');
      $(this).next('input.phone-hidden').val(saveNum);
    });
    //$('input.phone').on('keyup', test);
    //$('input.phone').on('keyup', test);
  },
  /**
   * 읽기만 가능한 textarea 높이 조절
   */
  setTextarea() {
    let $textarea = $('textarea[readonly="true"]');
    for(let i=0; i<$textarea.length; i++) {
      //$($textarea[i]).css('height', 'auto');
      $($textarea[i]).height( $textarea[i].scrollHeight );
      $($textarea[i]).css("overflow", "hidden");
    }
  },
  /**
   * textarea 현재 글자 수 보이게
   */
  setTextareaLength() {
    let $textarea = $('textarea');
    for(let i=0; i<$textarea.length; i++) {
      if($textarea.eq(i).next('.text-length').length <= 0) {
        $textarea.eq(i).after(`<div class="text-length"><span class="current">${$textarea.eq(i).val().length}</span> / ${$textarea.eq(i).attr('maxlength')}<div>`);
      }
    }
    $textarea.on('keyup', function() {
      let $current = $(this).next().find('.current');
      $current.html($(this).val().length);
      if($(this).val().length >= $(this).attr('maxlength')) {
        $current.html($(this).attr('maxlength'));
      }
    });
  },
  /**
   * Set Datepicker
   */
  setDatepicker() {
    let $datepicker = $('.datepicker');
    $datepicker.datepicker({ dateFormat: 'yy년 m월 d일' });
    $datepicker.datepicker().datepicker('setDate', 'today');
  },
  /**
   * Set Tooltip
   */
  setTooltip() {
    $('[data-toggle="tooltip"]').tooltip({
      content: function () {
        return $(this).prop('title');
      },
    });
    $('.detail-custom-tab [data-toggle="tab"]').tooltip({
      content: function () {
        return $(this).prop('title');
      },
    });

    $('[data-placement="top"]').tooltip({
      position: {
        my: 'center bottom',
        at: 'center top-8',
      },
    });
    $('[data-placement="right"]').tooltip({
      position: {
        my: 'left center',
        at: 'right+8 center',
      },
    });
    $('[data-placement="bottom"]').tooltip({
      position: {
        my: 'center top',
        at: 'center bottom+8',
      },
    });
    $('[data-placement="left"]').tooltip({
      position: {
        my: 'right center',
        at: 'left-8 center',
      },
    });
  },
  /**
   * Set Table Checkbox
   */
  setTableCheckbox() {
    $(document).on('change', '.ta-table-wrap input.all', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $eachCheckboxList = $tableWrap.find('input.each');
      $eachCheckboxList.prop('checked', $(e.target).prop('checked'));
    });
    $(document).on('change', '.ta-table-wrap input.each', (e) => {
      let $tableWrap = $(e.target).parents('.ta-table-wrap');
      let $allCheckbox = $tableWrap.find('input.all');
      let $eachLength = $tableWrap.find('input.each').length;
      let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
      if ($eachLength == $eachCheckedLength) {
        $allCheckbox.prop('checked', true);
      } else {
        $allCheckbox.prop('checked', false);
      }
    });
  },
  /**
   * Set Validation
   */
  setValidation() {
    let elements = document.querySelectorAll('input, textarea');
    for (let i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity(' ');
        if (!e.target.validity.valid) {
          elements[i].classList.add('is-invalid');
          if (e.target.type == 'file') {
            // label.classList.remove('btn-primary');
            // label.classList.add('btn-danger');
          }
        } else {
          if (e.target.type != 'file') {
            elements[i].classList.remove('is-invalid');
          } else {
            // label.classList.add('btn-primary');
            // label.classList.remove('btn-danger');
          }
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity(' ');
      };
      if ($(elements[i]).attr('required')) {
        elements[i].focusout = () => {
          // elements[i].classList.remove('is-valid');
          elements[i].classList.remove('is-invalid');
          if (elements[i].value == null || elements[i].value == '') {
            elements[i].classList.add('is-invalid');
          } else {
            // elements[i].classList.add('is-valid');
          }
        };
      }
    }
  },
  /**
   * Set Custom Select
   * if Change Select Call setCustomDropdownSelect(id);
   * @param {String} tagId
   */
  setCustomDropdownSelect(tagId = null) {
    const createCustomDropdownSelect = function (el) {
      let $originalSelect = $(el);
      let $originalOptgroupList = $originalSelect.find('optgroup');
      let $originalOptionList = $originalSelect.find('option');
      let placeholder = $originalSelect.find('option:selected').text();
      //let placeholder = '선택';
      let isPlaceholder = false;

      if ($(el).parent().find('.custom-dropdown-select').length <= 0) {
        let newSelect = `
        <div class="dropdown custom-dropdown-select">
        <button class="btn dropdown-toggle" type="button" ${
          $originalSelect.attr('disabled') ? 'tabindex="-1"' : ''
        } data-toggle="dropdown" aria-expanded="false">
          ${placeholder}
        </button>
        <div class="dropdown-menu">
        `;
        if ($originalOptgroupList.length > 0) {
          for (let i = 0; i < $originalOptgroupList.length; i++) {
            $originalOptionList = $originalOptgroupList.eq(i).find('option');
            newSelect += `
            <div class="dropdown-optgroup">
            <h6>${$originalOptgroupList[i].label}</h6>
            `;
            for (let j = 0; j < $originalOptionList.length; j++) {
              if ($originalOptionList[j].value == '_placeholder') {
                isPlaceholder = true;
                //placeholder = $originalOptionList[j].textContent;
              } else {
                newSelect += `
                <a href="javascript:void(0)"
                class="dropdown-item whwywhy ${$($originalOptionList[j]).prop('selected') ? 'active' : ''}"
                data-value="${$originalOptionList[j].value}"
                data-toggle="tooltip" data-placement="bottom"
                title="${$originalOptionList[j].title}">
                ${$originalOptionList[j].text}
                </a>
                `;
              }
            }
            newSelect += '</div>';
          }
        } else {
          for (let i = 0; i < $originalOptionList.length; i++) {
            if ($originalOptionList[i].value == '_placeholder') {
              isPlaceholder = true;
              //placeholder = $originalOptionList[i].textContent;
            } else {
              newSelect += `
              <a href="javascript:void(0)"
              class="dropdown-item ${$($originalOptionList[i]).prop('selected') ? 'active' : ''}"
              data-toggle="tooltip" data-placement="bottom"
              data-value="${$originalOptionList[i].value}"
              title="${$originalOptionList[i].title}">
              ${$originalOptionList[i].text}</a>
              `;

              // 선택 바꾸기!!
              if($($originalOptionList[i]).prop('selected') == true) {

              }
            }
          }
        }
        newSelect += `
        </div>
        </div>
        `;
        $originalSelect.after(newSelect);
        $originalSelect.next().find('.btn').text(placeholder);

        if ($(el).parent().find('a.dropdown-item.active').length > 0) {
          //$('#' + $originalSelect.attr('id') + 'Dropdown').text($(el).parent().find('a.dropdown-item.active').text());
          $(el).find('.dropdown-toggle').text($(el).parent().find('a.dropdown-item.active').text());
        } else {
          if (isPlaceholder) {
            $(el).parent().find('.custom-dropdown-select').addClass('dropdown-placeholder');
          }
        }
      }
    };
    if (tagId == null) {
      let $customSelect = $('.ta-custom-dropdown-select-wrap select');
      $(document).off('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item');
      $(document).on('click', '.ta-custom-dropdown-select-wrap .custom-dropdown-select .dropdown-item', function () {
        let $this = $(this);
        let $originalSelect = $this.parents('.ta-custom-dropdown-select-wrap').find('select');
        let $originalOptionList = $originalSelect.find('option:not([value="_placeholder"]');
        let $index = $this.parents('.dropdown-menu').find('.dropdown-item').index($this);
        let $btn = $this.parents('.custom-dropdown-select').find('.btn');
        $originalOptionList.eq($index).prop('selected', true);
        $originalSelect.trigger('change');
        $btn.text($this.text());
        $btn.parent().removeClass('dropdown-placeholder');
        $this.parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
        $this.addClass('active');
        $originalSelect.removeClass('is-invalid');
        $originalSelect.closest('div').parent().find('.invalid-feedback').remove();
      });
      $customSelect.each((index, el) => {
        createCustomDropdownSelect(el);
      });
    } else {
      let $originalSelect = $(`#${tagId}`);
      $originalSelect.parent().find('.custom-dropdown-select').remove();
      createCustomDropdownSelect($(`#${tagId}`)[0]);
    }
    Utils.setTooltip();
  },
  /**
   * Start Loading
   */
  startLoading() {
    let wrapElmnt = document.querySelector('.spinner-wrap');
    let eduLesson = document.querySelector('.layout-wrap .edu-lesson');

    if (wrapElmnt) {
      wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
    } else {
      wrapElmnt = document.createElement('div');
      wrapElmnt.classList.add('spinner-wrap');
      wrapElmnt.dataset.task = 1;
      let loadingElmnt = document.createElement('div');
      loadingElmnt.classList.add('spinner-border');
      let span = document.createElement('span');
      span.classList.add('sr-only');
      span.innerHTML = 'Loading...';
      loadingElmnt.appendChild(span);
      wrapElmnt.appendChild(loadingElmnt);

      let body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
      body.appendChild(wrapElmnt);
      if(eduLesson) {
        wrapElmnt.classList.add('edu-loading');
      }
    }
  },
  /**
   * End Loading
   */
  endLoading() {
    let wrapElmnt = document.querySelector('.spinner-wrap');
    let taskCount = wrapElmnt.dataset.task;

    if (taskCount > 1) {
      wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
    } else {
      wrapElmnt.style.opacity = 0;
      setTimeout(function () {
        wrapElmnt.remove();
      }, 300);
      let body = document.getElementsByTagName('body')[0];
      body.style.overflow = 'auto';
    }
  },
  /**
   * Ajax
   * ex) Utils.request('GET', 'test.json', null, (data) => { console.log(data) });
   * @param {String} type
   * @param {String} url
   * @param data
   * @param {Function} func
   */
  request(type, url, data, func) {
    $.ajax({
      type,
      url,
      data,
      success: (data) => {
        func(data);
      },
      error: (xhr, status, error) => {
        console.log(`status: ${status}\nmessage: ${error}`);
      },
    });
  },
  /**
   * Number to KRW format
   * ex) 1000000 -> 1,000,000
   * @param {Number} value
   * @returns {String}
   */
  numberFormatter(value) {
    if (value != '' && value != null && typeof value == 'number') {
      value = String(value)
        .replace(/[^\d]+/g, '')
        .replace(/(^0+)/, '')
        .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    } else {
      value = 0;
    }
    return value === '' ? 'NaN' : value;
  },
  /**
   * Get input[type=file] detail
   * @param {Element} elmnt
   * @returns {Object}
   */
  getFileDetail(elmnt) {
    //파일 경로.
    let filePath = elmnt.value;
    //전체경로를 \ 나눔.
    let filePathSplit = filePath.split('\\');
    // 파일 전체명
    let originalFileName = filePathSplit[filePathSplit.length - 1];
    //파일확장자 앞 .의 index
    let lastDot = originalFileName.lastIndexOf('.');
    //파일명 : .으로 나눈 앞부분
    let fileName = originalFileName.substring(0, lastDot);
    //파일 확장자 : .으로 나눈 뒷부분
    let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
    //파일 크기
    let fileSize = elmnt.files[0].size;

    let object = {
      originalName: originalFileName,
      name: fileName,
      ext: fileExt,
      size: fileSize,
    };

    return object;
  },
  /**
   * Byte to size
   * return ex) 5 GB
   * @param {Number} byte
   * @returns {String}
   */
  byteFormatter(byte) {
    let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    if (byte == 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
    return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
  },
  /**
   * Set date format
   * @param {String} date
   * @returns {Object}
   */
  dateFormatter(date) {
    if ((date == '' || date == null) && typeof date == 'string') {
      return '';
    }
    const addZero = (num, digits) => {
      var zero = '';
      num = num.toString();

      if (num.length < digits) {
        for (var i = 0; i < digits - num.length; i++) {
          zero += '0';
        }
      }
      return zero + num;
    };
    // Safari Invalid Date 로 인한 replace
    date = date.substring(0, date.lastIndexOf('.')).replace(/-/g, '/').replace('T', ' ');
    let newDate = new Date(date);

    let yyyy = newDate.getFullYear();
    let mm = addZero(newDate.getMonth() + 1, 2);
    let m = newDate.getMonth() + 1;
    let dd = addZero(newDate.getDate(), 2);
    let d = newDate.getDate();

    let object = {
      slash: yyyy + '/' + mm + '/' + dd,
      dot: yyyy + '.' + mm + '.' + dd,
      dash: yyyy + '-' + mm + '-' + dd,
      word: yyyy + '년 ' + m + '월 ' + d + '일',
    };

    return object;
  },

  // clear-input
  inputClear() {
    $(document).on('click', '.clear-input .btn-clear', function() {
      $(this).parents('.clear-input').find('input').val('');
      $(this).removeClass('show');
    });
    $(document).on('keydown', '.clear-input input', function() {
      if(this.value != '') {
        $(this).parents('.clear-input').find('.btn-clear').addClass('show');
      } else {
        $(this).parents('.clear-input').find('.btn-clear').removeClass('show');
      }
    });
  },
  /**
   * header - searchMain
   */
  searchMain(recentSearch) {
    // recentSearch 값을 record-list 넣기
    $.each(recentSearch, function (index, item) {
      $('.result-search-record .record-list').append(`
        <li class="record-item">
          <i class='material-icons time'>schedule</i>
          <a href="${item.link}" class="record-link"> ${item.value} </a>
          <a href="javascript:void(0)" type="button" class="search-del"><i class="material-icons">close</i></a>
        </li>
      `)
    })

    $( ".search-main" ).on('focus', function() {
      $('.result-search-record').addClass('show');
    });
    

    $('.search-del').on('click', function() {
      let index = $(this).parents('.record-item').index();
      var searchHistoryArr = JSON.parse(localStorage.getItem('searchHistory'));
      // 선택한 값의 index를 구해서 배열 안 값 삭제
      // 프론트단 item pc/mobile index 구하기
      let total = $('.record-item').length;
      let m_index = (total / 2) + index - 1;
      // 프론트단 item 삭제
      $(`.record-item:eq(${index})`).remove();
      $(`.record-item:eq(${m_index})`).remove();
      // localstorage remove history
      searchHistoryArr.splice(index, 1);
      localStorage.setItem('searchHistory', JSON.stringify(searchHistoryArr));
    });
    $('.search-del-all').on('click', function() {
      localStorage.removeItem('searchHistory');
      // 프론트단 item 삭제
      $('.result-search-record').remove();
    });
  },

  setHeaderEvent() {
    const $btnMobileMenu = $('.navbar .mobile-menu a');
    const $mobileMenu = $('.navbar-nav.mobile');
    const $navItem = $('.layout-header .navbar .nav-item');
    const $navLink = $('.layout-header .navbar .nav-item > a');

    let pathname = window.location.pathname.split('/')[2];
    let pathnav = $(`.layout-header .navbar .nav-item[data-path="${pathname}"]`)
    pathnav.addClass('active-only');
  
    // #region mobile menu event
    $btnMobileMenu.on('click', () => {
      $mobileMenu.addClass('active');
      //$mobileMenu.addClass('visible');
      //setTimeout(() => {
      //  $('.header-backdrop').addClass('show');
      //}, 100);
    });
    $(document).on('click', '.btn-navbar-close', () => {
      //$('.header-backdrop').removeClass('show');
      $mobileMenu.removeClass('active');
      //setTimeout(() => {
      //  $('.header-backdrop').remove();
      //  $mobileMenu.removeClass('visible');
      //}, 300);
    });
    // #endregion mobile menu event
    // #region tab event
    $navItem.on('focusin', function () {
      removeMenuActive();
      $(this).addClass('active');
    });
    $navItem.on('click', function () {
      $navItem.removeClass('active-only');
      $(this).addClass('active-only');
      $(this).removeClass('active');
    });
    $navLink.on('focusin', function () {
      removeMenuActive();
    });
    $(document).on('mousedown', function () {
      removeMenuActive();
    });
    $('.layout-main, .layout-footer').on('focusin', function () {
      removeMenuActive();
    });
    function removeMenuActive() {
      $('.layout-header .navbar .nav-item').removeClass('active');
    }
    // #endregion tab event
    
    // 모바일에서 검색창 열기
    $('.main-search-open').on('click', () => {
      $('.search-main-wrap').addClass('active');
    });

    // 검색창 닫기
    $('.main-search-close').on('click', () => {
      $('.result-search-record').removeClass('show');
      $('.search-main-wrap').removeClass('active');
    });

    $('.search-main-wrap').on('click', () => {
      $('.search-main-wrap').addClass('active');
      // 검색창 닫기
      $('body').on('click', function(e){
        if($(e.target).parents('.ta-search-wrap').length != 0 || $(e.target).hasClass('search-del')) {
          //e.preventDefault();
          //return;
          if($(e.target).hasClass('main-search-close')) {
            $('.result-search-record').removeClass('show');
            $('.search-main-wrap').removeClass('active');
          }
        } else {
          $(this).off(e);
          $('.result-search-record').removeClass('show');
          $('.search-main-wrap').removeClass('active');
        }
      });
    });
  }
};

// 메인 hero 이미지 이벤트
function setMainBanner() {
  const mainBanner = document.querySelector('.banner-main');
  const $imgWrap = $('.main-hero .banner-images');

  $imgWrap.addClass('running');
  setInterval(() => $imgWrap.removeClass('running'), 5000);
  setInterval(() => $imgWrap.addClass('running'), 10000);

  mainBanner.addEventListener('mousemove', function(e) {
    $imgWrap.removeClass('running').addClass('banner');
    $imgWrap.children('img').each(function(index) {
      // 랜덤 값 내기
      let number = 1 + Math.floor(Math.random() * 8);
      number = number * 5;

      let transX = ((e.x - window.innerWidth ) / 7) - number;
      let transY =( e.y / -10 ) - number;
      //let transR = index >= 3 ? (e.screenX - window.innerWidth) / 2 : 0;
      let transR = index == 0 ? (transX / 4) : index >= 4 ? (transX * 2) : (transX / 2);

      // 작은 .ele-n 요소
      //if(index >= 4) {
      //  let newR = parseInt($(this).css('right')) - transX;
      //  console.log(newR);
      //  $(this).css("right", newR)
      //}
      $(this).css("transform", "translateX(" + transX + "px) translateY(" + transY + "px)" + "rotate(" + transR + "deg)");
    });
  });


  mainBanner.addEventListener('mouseout', function(e) {
    $imgWrap.addClass('running').removeClass('banner');
    $imgWrap.children('img').each(function(index) {
      this.style = '';
      //$(this).css("transform", "translateX(0px) translateY(0px)");
    });
  });

}


// 비밀번호 필드와 비밀번호 재확인 필드의 값을 비교하는 함수
function validatePassword() {
  var isValid = true;
  var password = $('#password').val();
  var confirmPassword = $('#confirm_password').val();

  let result = {
    pwValid: true,
    confirmValid: true,
    isValid: true,
  }
  if(!is_valid_password(password)) {
    $('#password').addClass('is-invalid');
    result.pwValid = false;
  } else {
    $('#password').removeClass('is-invalid');
    result.pwValid = true;
  }
  if (password != confirmPassword) {
    $('#confirm_password').addClass("is-invalid");
    $(`#confirm_password + .invalid-feedback`).show();
    $('.password-change .btn[type=submit]').attr('disabled', true);
    result.confirmValid = false;
  } else {
    $('#confirm_password').removeClass("is-invalid");
    $(`#confirm_password + .invalid-feedback`).hide();
    $('.password-change .btn[type=submit]').attr('disabled', false);
    result.confirmValid = true;
  }
  result.isValid = result.confirmValid && result.confirmValid;
  return result;
};

$("#password").on("keyup", function() {
  let result = validatePassword();
  if(result.pwValid) {
    $(this).parent('.input-validate').find('button').css('display', 'block');
  } else {
    $(this).parent('.input-validate').find('button').css('display', 'none');
  }
});
$("#confirm_password").on("keyup", function() {
  let result = validatePassword();
  if(result.confirmValid) {
    $(this).parent('.input-validate').find('button').css('display', 'block');
  } else {
    $(this).parent('.input-validate').find('button').css('display', 'none');
  }
});


function bannerSwiper() {
  let leng = $('.banner-swiper .swiper-slide').length;
  let is_loop = leng <=1 ? false : true;
  const bannerSwiper = new Swiper('.banner-swiper', {
    loop: is_loop,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    speed : 1000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
  if(!is_loop) {
    $('.swiper-btn').css('display', 'none');
  }

  $(".banner-swiper .swiper-pause").on('click', function(){
    bannerSwiper.autoplay.stop();
  });

  $(".banner-swiper .swiper-play").on('click', function(){
    bannerSwiper.autoplay.start();
  });
}

function mainSwiper() {
  function reSlide() {
    let budgeH = getBreakpoint() == 'lg' || getBreakpoint() == 'xl' ? 36 : getBreakpoint() == 'md' ? 34 : 32 ;
    $('.main-swiper .swiper-slide.cloned').remove();
    let $slide = $('.main-swiper .swiper-slide');
    let $budge = $('.main-swiper .swiper-slide .budge-wrap');
    let time = Math.round($budge.height() / budgeH);
    time = Math.round((time - 2) / 2);

    for(let i=0; i<time; i++) {
      let newSlide = $slide.clone();
      newSlide.addClass('cloned');
      newSlide.find('.budge-wrap').css('top', -((budgeH*2)*(i+1)));
      $(`.main-swiper .swiper-wrapper`).append(newSlide);
    }
    if(time > 0) {
      $('.main-swiper .main-swiper-next').css('display', 'none');
      $('.main-swiper .main-swiper-prev').css('display', 'none');
    }
  }

  const bannerSwiper = new Swiper('.main-swiper', {
    //loop: true,
    //autoplay: {
      //delay: 2500,
      //disableOnInteraction: false,
    //},
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-container .main-swiper-next",
      prevEl: ".swiper-container .main-swiper-prev",
      clickable: true,
    },
    on: {
      resize: function() {
        reSlide();
        bannerSwiper.update();
      }
    }
  });

  // 처음 슬라이드 왼쪽은 opacity 주기
  $(".swiper-container .main-swiper-prev").css('opacity', '0.5');
  bannerSwiper.on('slideChange', function () {
    // 현재 슬라이드 인덱스와 슬라이드 전체 수 가져오기
    var currentIndex = bannerSwiper.realIndex;
    var totalSlides = bannerSwiper.slides.length;
  
    // 이전 버튼 투명도 설정
    if (currentIndex === 0) {
      $(".swiper-container .main-swiper-prev").css('opacity', '0.5');
    } else {
      $(".swiper-container .main-swiper-prev").css('opacity', '1');
    }
  
    // 다음 버튼 투명도 설정
    if (currentIndex === totalSlides - 1) {
      $(".swiper-container .main-swiper-next").css('opacity', '0.5');
    } else {
      $(".swiper-container .main-swiper-next").css('opacity', '1');
    }
  });
}


function reviewSwiper() {
  const reviewSwiper = new Swiper('.review-swiper', {
    loop: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 2.4,
    breakpoints: {
      360: {slidesPerView: 2.4,},
      768: {slidesPerView: 2.8,},
      1024: {slidesPerView: 4.8,},
    },
  });
}

function getBreakpoint() {
  let breakpoint = getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content');
  breakpoint = breakpoint.replaceAll('"', '');
  return breakpoint;
};

// isghome에서 긁어옴
function setSelect2() {
  $('.js-example-placeholder-single')
    .val('')
    .select2({
      placeholder: $(this).data('placeholder'),
      language: {
        noResults: function noResults() {
          return '검색 결과가 없습니다.';
        },
      },
    });
  $('div[class^=select2] select').select2({
    placeholder: $(this).data('placeholder'),
    language: {
      noResults: function noResults() {
        return '검색 결과가 없습니다.';
      },
    },
  });
  $('.select2_wrap select').select2({
    minimumResultsForSearch: -1,
    placeholder: $(this).data('placeholder'),
    language: {
      noResults: function noResults() {
        return '검색 결과가 없습니다.';
      },
    },
  });
  $('.add_tag').select2({
    tags: true,
    placeholder: $(this).data('placeholder'),
    language: {
      noResults: function noResults() {
        return '검색 결과가 없습니다.';
      },
    },
  });
  $('.modal_customer .select2-search-wrap select').select2({
    maximumSelectionLength: 3,
    language: {
      maximumSelected: function maximumSelected() {
        return '최대 3개까지 선택 가능합니다.';
      },
      noResults: function noResults() {
        return '검색 결과가 없습니다.';
      },
    },
  });
  // select2_wrap 담당자 배정 custom
  setTimeout(function () {
    $('.select2_wrap.custom').find('textarea').attr('readonly', true);
  }, 100);
}

function researchSel() {
  $('.research .select2-search-wrap select').on('select2:select', function (e) {
    var researchWrap = $(this).parents('.research');
    if (!researchWrap.hasClass('update')) {
      $(this).val('').trigger('change');
      var id = e.params.data.id;
      var text = e.params.data.text;
      var isOk = true;
      var tag =
        '<li id=' +
        id +
        '>' +
        '<span>' +
        text +
        '</span>' +
        '<a href="javascript:void(0)" class="research_tag"><i class="xi-close-min"></i></a>' +
        '<input type="hidden" name="research_field" value="' +
        e.params.data.id +
        '" title="연구분야">' +
        '</li>';
      $('.research ul li').each(function () {
        if ($(this).attr('id') == id) {
          isOk = false;
        }
      });
      if ($('.research ul li').length >= 3) {
        alert('연구 분야는 최대 3개만 선택 가능합니다.');
        return;
      }

      if (isOk) {
        $('.research ul').prepend(tag);
      } else {
        alert('이미 선택한 연구분야입니다.');
      }
    }
  });
  $(document).on('click', '.research_tag', function () {
    $(this).parent().remove();
  });
}

function lectureSticky() {
  let indexTop = parseInt(document.querySelector(".index").offsetTop);
  let footerTop = parseInt(document.querySelector(".lecture-footer").offsetTop);
  footerTop = (footerTop-750);

  let headerH = $('.layout-header').height();
  let checkMoblie = getBreakpoint();

  $('.layout-wrap').on('scroll', function() {
    let scrollH = $('.layout-wrap').scrollTop();
    scrollH += headerH;
    // index 위에 붙기
    if(scrollH >= indexTop) {
      $('.lecture-template .index').addClass('sticky');
    } else {
      $('.lecture-template .index').removeClass('sticky');
    }
    // 베너 바닥에 붙기
    if(scrollH >= footerTop) {
      $('.lecture-template .lecture-footer .container').removeClass('sticky');
    } else {
      $('.lecture-template .lecture-footer .container').addClass('sticky');
    }

  if($("#composition").length && $("#feature").length) {
    // 스크롤 높이에 따라 index 활성화 시키기
    //let scrollH2 = scrollH - 68;
    let scrollH2 = scrollH - 30;
    let outline = parseInt(document.querySelector("#outline").offsetTop) - 76; //(header - index)
    let composition = $("#composition").length > 0 ? parseInt(document.querySelector("#composition").offsetTop) - 76 : outline;
    let feature = $("#feature").length > 0 ? parseInt(document.querySelector("#feature").offsetTop) - 76 : composition;
    let instructor = $("#instructor").length > 0 ? parseInt(document.querySelector("#instructor").offsetTop) - 76 : feature;
    
    if(scrollH2 >= outline && scrollH2 < composition) {
      $('.lecture-template .index .row a').removeClass('active');
      $('.lecture-template .index .row a').eq(0).addClass('active');

    } else if(scrollH2 >= composition && scrollH2 < feature) {
      if($("#composition").length > 0) {
        $('.lecture-template .index .row a').removeClass('active');
        $('.lecture-template .index .row a').eq(1).addClass('active');
      }
    } else if(scrollH2 >= feature && scrollH2 < instructor) {
      if($("#feature").length > 0) {
        $('.lecture-template .index .row a').removeClass('active');
        $('.lecture-template .index .row a').eq(2).addClass('active');
      };
    } else if(scrollH2 >= instructor) {
      if($("#instructor").length > 0) {
        $('.lecture-template .index .row a').removeClass('active');
        $('.lecture-template .index .row a').eq(3).addClass('active');
      };
    }
  }
  })

  
  if($("#composition").length>0 && $("#feature").length>0) {
    $('.lecture-template .index a').on('click', function() {
      $('.lecture-template .index a').removeClass('active');
      $(this).addClass('active');
      if($("#instructor").length<=0) {$('.lecture-template .index .row a').eq(3).removeClass('active')}
    });
  } else {
    $('.lecture-template .index a').addClass('disabled');
    $('.lecture-template .index a').on('click', function(e) {
      e.preventDefault();
    });
  }

  document.querySelectorAll('.index a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      const targetId = this.getAttribute('href');
      const targetElement = document.querySelector(targetId);
      const scrolledBox = document.querySelector('.layout-wrap');

      if (targetElement && scrolledBox) {
        const offset = checkMoblie == 'sm' ? headerH + 30 : headerH; // 간격 설정
        const targetPosition = targetElement.getBoundingClientRect().top + scrolledBox.scrollTop - offset;
        scrolledBox.scrollTo({
          top: targetPosition,
          behavior: 'smooth' // 부드러운 스크롤
        });
      }
    });
  });
}


function execDaumPostcode() {
  new daum.Postcode({
    oncomplete: function(data) {
      document.getElementById('id_post_number').value = data.zonecode;
      document.getElementById("id_address").value = data.roadAddress;
      document.getElementById("id_address_en").value = data.addressEnglish;
      document.getElementById("id_address_detail").focus();
    }
  }).open();
}
function alert_username_null () {
  $('#message_title').html("아이디(기관 이메일) 항목 에러");
  $('#message_detail').html("이메일 형태로 입력하여주세요.");
  $.magnificPopup.open({items: {src: "#id_alert_modal", type: "inline"}})
  $('#id_username').focus()
};


function is_valid_email(email) {
  const format = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return format.test(email)
};

function is_valid_password(password) {
  const format = /^.*(?=^.{8,32}$)(?=.*\d)(?=.*[a-zA-Z]).*$/;
  return format.test(password)
};

function is_valid_phone(phone) {
  //const format = /^\d{13}$/;
  const format = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/;
  return format.test(phone)
};

function is_valid_date(date) {
  //const format = /^\d{13}$/;
  //const format = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
  let is_date = !isNaN(Date.parse(date));
  return is_date
};

function is_Eng(text) {
  const format = /[a-zA-Z]/;;
  return format.test(text)
};


function invalid_add(input) {
  $(input).addClass('is-invalid');
  $(input).parents('.input-wrap').find('.invalid-feedback').css('display', 'block');
}
function invalid_remove(input) {
  $(input).removeClass('is-invalid');
  $(input).parents('.input-wrap').find('.invalid-feedback').css('display', 'none');
}


// #region 회원가입 소속정보 기관 or 개인
$(document).on('click', '.ta-radio-wrap input[name="user_type"]', function () {
  let $parent = $(this).parents('.input_info');
  let $position = $parent.find('.agency-position');
  let addressLabel = $('label[for=id_address]');
  let addressInput = $('#id_address');

  if($(this).val() == 'organization') {
    addressLabel.html('기관주소');
    addressInput.attr('placeholder', '기관주소를 입력해주세요.');
    $position.find('input').attr('disabled', false);
    $position.find('select').addClass('required');
    $position.find('input').addClass('required');
    $position.removeClass('none');
  } else if($(this).val() == 'personal') {
    addressLabel.html('주소');
    addressInput.attr('placeholder', '주소를 입력해주세요.');
    $position.find('input').attr('disabled', true);
    $position.find('select').removeClass('required');
    $position.find('input').removeClass('required');
    $position.addClass('none');
  }
});


// #region 사진첨부
$(document).on('change', 'input[type=file]#ask_file', function (e) {
  if (uploadPicture($(this))) {
    let parent = $(this).parents('.file').find('ul');

    if ($(this).val() != '' && $(this).val() != null) {
      setThumbnail(parent, e);
    }
  }
});

$(document).on('click', '.remove-file', function () {
  $(this).parents('li').remove();
});


function uploadPicture(elmnt) {
  const MAX_LENGTH = 2;
  let fileLength = elmnt[0].files.length;
  let totalLength =
  elmnt.parents('.file').find('li').length + fileLength - 1;

  if (!isImage(elmnt) && elmnt.val() != '') {
    alert('이미지 파일만 등록 가능합니다.');
    elmnt.val('');
    return false;
  }
  if (!getFileDetail(elmnt, 'size') / 1024 / 1024 > 10) {
    alert('이미지 용량이 10MB를 초과했습니다.');
    elmnt.val('');
    return false;
  }
  if (totalLength > MAX_LENGTH) {
    alert('첨부파일은 최대 2개까지 등록 가능합니다.');
    elmnt.val('');
    return false;
  }
  return true;
}
// #endregion 사진첨부

// 이미지파일인지 확인
function isImage(file) {
  let isImage = false;
  let ext = getFileDetail(file, 'ext');
  if (
    ext == 'jpeg' ||
    ext == 'jpg' ||
    ext == 'png' ||
    ext == 'gif' ||
    ext == 'bmp'
  ) {
    isImage = true;
  }

  return isImage;
}

function getFileDetail(file, type) {
  if (file.val() == '') return;
  //파일 경로.
  let filePath = file.val();
  //전체경로를 \ 나눔.
  let filePathSplit = filePath.split('\\');
  //전체경로를 \로 나눈 길이.
  let filePathLength = filePathSplit.length;
  //마지막 경로를 .으로 나눔.
  let fileNameSplit = filePathSplit[filePathLength - 1].split('.');
  //파일명 : .으로 나눈 앞부분
  let fileName = fileNameSplit[0];
  //파일 확장자 : .으로 나눈 뒷부분
  let fileExt = fileNameSplit[fileNameSplit.length - 1].toLowerCase();
  //파일 크기
  let fileSize = file[0].files[0].size;

  switch (type) {
    case 'path':
      return filePath;
    case 'name':
      return fileName;
    case 'ext':
      return fileExt;
    case 'size':
      return fileSize;
  }
}

// 파일 썸네일 세팅하기
function setThumbnail(elmnt, event) {
  let files = event.target.files;

  // 인풋 복사하고 기존 값 지우기
  let $input = $(event.currentTarget).clone();
  $input.attr('id', '');
  $input.attr('name', 'image');
  $input.attr('class', 'dkdk');

  let length = elmnt.parents('.file').find('li').length - 1;

  if (files) {
    $.each(files, function (index) {
      let reader = new FileReader();
      let attach_id = 'attachment_' + length;

      reader.onload = function (event) {
        let img = `
        <li class="add-image-${length}">
          <div class="img-wrap" data-id="${attach_id}">
            <img src="${event.target.result}" alt="ask-file-img"/>
            <button class="remove-file"><i class="material-icons">close</i></button>
          </div>
        </li>
        `
        elmnt.append(img);
        $(elmnt).find(`.add-image-${length}`).append($input);
      };
      reader.readAsDataURL(files[index]);

    });
  }
  $(event.currentTarget).val('');
}

//modal
//button의 data-link는 나중을 위해서 넣은값.
//백 작업할 때, 필요 시 지우거나 원하는 값으로 수정할 것.
function makeModal(content, size) {
  // 버튼 개수에 따라 다르게 들어가기
  let modal_button = '';
  for(let i=0; i<content.button.length; i++) {
    if (content.button[i].close != null && content.button[i].close) {
      modal_button += `<a href="${content.button[i].link}" class="btn ${content.button[i].style} btn-alone" data-dismiss="modal">${content.button[i].text}</a>`;
    } else {
      modal_button += `<a href="${content.button[i].link}" class="btn ${content.button[i].style} btn-alone">${content.button[i].text}</a>`;
    }
  }

  // 모달 들어갈 container 찾아서 모달 넣기

  if(size) {
    $('.modal-container').append(`
      <div class="modal fade" id="${content.id}" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-${size} ">
        <div class="modal-content">
          <div class="modal-title text-center">
            <h3>${content.header}</h3>
          </div>
          <div class="modal-inner">
            ${content.body}
          </div>
          <div class="modal-footer justify-content-center">
            ${modal_button}
          </div>
        </div>
      </div>
      </div>
    `);
  } else {
    $('.modal-container').append(`
      <div class="modal fade" id="${content.id}" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog ">
        <div class="modal-content">
          <div class="modal-title text-center">
            <h3>${content.header}</h3>
          </div>
          <div class="modal-inner">
            <p class="text-center">${content.body}</p>
          </div>
          <div class="modal-footer justify-content-center">
            ${modal_button}
          </div>
        </div>
      </div>
      </div>
    `);
  }
}

function showToast(text) {
  $('body').prepend(`<div class="toast">${text}</div>`);
  setTimeout(() => $('.toast').addClass('show'), 500);
  setTimeout(() => $('.toast').removeClass('show'), 3000);
  setTimeout(() => $('.toast').remove(), 3300);
};

// tab 버튼 이벤트
function tabSelect() {
  $('.tab-menu li').on('click', function() {
    let tab_content = $(this).parents('.tab-menu').data('tabContent');
    let tab = $(this).children().data('tab');
    
    $(this).parents('.tab-menu').find('li').removeClass('active');
    $(this).addClass('active');

    $(`${tab_content} .tab`).removeClass('show');
    $(`${tab_content} ${tab}`).addClass('show');
  })
}

function listOrder() {
  $('.order-type a').on('click', function() {
    $(this).parents('.order-type').find('a').removeClass('active');
    $(this).addClass('active');
  });
}

function dropdownSelect() {
  let $activeItem = $('.dropdown-select .dropdown-item.active');
  $activeItem.parents('.dropdown-select').find('.dropdown-toggle').find('span').html($activeItem.html());
  $('.dropdown-select .dropdown-item').on('click', function() {
    $(this).parents('.dropdown-menu').find('.dropdown-item').removeClass('active');
    $(this).addClass('active');

    let $select = $(this).parents('.dropdown-select').find('.dropdown-toggle');

    $select.find('span').text($(this).text());
    $select.data('value', $(this).data('value'));

  });
}

function internshipDate() {
  $('.form-white').on('keyup', 'input.datepicker2', function() {
    let value = $(this).val();
    if (!value) { return ""; }
    value = value.replace(/[^0-9]/g, "");

    let result = [];
    let restNumber = "";
  
    // yyyy-mm-dd
    result.push(value.substr(0, 4));
    restNumber = value.substring(4);

    if (restNumber.length === 4) {
      // 4자리만 남았을 때는 mm-dd
      result.push(restNumber.substring(0, 2));
      result.push(restNumber.substring(2));
    } else {
      result.push(restNumber.substring(0, 2));
      result.push(restNumber.substring(2));
    }
  
    //return result.filter((val) => val).join("-");
    $(this).val(result.filter((val) => val).join("-"));

    // 시작과 끝이 있는 range 인 경우,
    let is_start = $(this).hasClass('start');
    let is_end = $(this).hasClass('end');
    let $pair = is_start || is_end ? $(this).parents('.ta-date-pair-wrap') : false;
    if(is_start) {
      let end = $pair.find('.datepicker2.end').val();
      let is_wrong = $(this).val() >= end;
      if(is_wrong) {
        $pair.addClass("is-invalid");
      } else {
        $pair.removeClass("is-invalid");
      }
    }  
    if(is_end) {
      let start = $pair.find('.datepicker2.start').val();
      let is_wrong = $(this).val() <= start;
      if(is_wrong) {
        $pair.addClass("is-invalid");
      } else {
        $pair.removeClass("is-invalid");
      }
      //if(is_wrong) {invalid_add(this);
      //} else {invalid_remove(this);}
    }  
  });
  
}

function putPhoneHypen($input) {
  let value = $input.val();
  if(value != '') {
    let result = [];
    result.push(value.substr(0, 3));
    let restNumber = value.substring(3);
    if (restNumber.length === 7) {
      // 7자리만 남았을 때는 xxx-yyyy
      result.push(restNumber.substring(0, 3));
      result.push(restNumber.substring(3));
    } else {
      result.push(restNumber.substring(0, 4));
      result.push(restNumber.substring(4));
    }
    $input.val(result.filter((val) => val).join("-"));
  }
}

//function outHypen($input) {
//  $input.val($input.val().replace(/-/g, ''));
//}

function btnTooltip(btn, msg) {
  $(btn).css('pointer-events', 'auto');
  $(btn).css('position', 'relative');
  $(document).on('mouseover', btn, function() {
    let tooltip = '<div class="btn-tooltip">' + msg + '</div>';
    $(this).append(tooltip);
  });
  
  $(document).on('mouseout', btn, function(e) {
    $('.btn-tooltip').remove();
  });
}


function scrollHeader() {
  let h = $('.banner-main').length > 0 ? $('.banner-main').next().offset().top - 56 : 30;
  $('.layout-wrap').on('scroll', function() {
    if ($(this).scrollTop() > h) {
      $('.layout-header').addClass('scroll-active');
    } else {
      $('.layout-header').removeClass('scroll-active');
    }
  });
}